<template>
  <div class="shop-order">
    <div class="order-head">
      <shop-header type="other"></shop-header>
      <page-title title="确认订单" sub-title="Confirm the order"></page-title>
    </div>
    <match ref="matchRefs" @changeObj="change">
    </match>
    <buy-info class="mt_60" ref="buyInfoRefs" @changeObj="change"></buy-info>
    <div class="footer">
      <confirm-order class="confirm-order" :total="totalPrice" @confirm="handleClick"></confirm-order>
    </div>
    <confirm-dialog :order-info="orderInfo" @closeConfirm="closeConfirm" :form-data="addressInfo" :total="totalPrice"
                    ref="confirmDiaRefs"/>
  </div>
</template>

<script setup>
import {getCurrentInstance, onMounted, reactive, ref} from "vue";
import ShopHeader from "@views/shop/components/shopHeader/index.vue";
import PageTitle from "@views/shop/components/pageTitle.vue";
import Match from "../match.vue";
import BuyInfo from "@views/shop/order/components/buyInfo/index.vue";
import ConfirmOrder from "@views/shop/order/components/confirmOrder.vue";
import ConfirmDialog from "@views/shop/order/components/confirmDialog/index.vue";
import {createShopOrder, getShopOrderPrice} from '@utils/api/shop.js'
import {useStore} from "vuex";
import {reactiveAssign} from "@utils/tool";
import {listenBack} from "@views/shop/utils";

const confirmDiaRefs = ref(null)
const orderInfo = reactive({})
const buyInfoRefs = ref(null)
const matchRefs = ref(null)
const store = useStore()
const {proxy} = getCurrentInstance();
const totalPrice = reactive({
  total: "0",
  diff: "0"
})

const addressInfo = ref({})

listenBack()
const change = () => {
  if (!matchRefs.value || !buyInfoRefs.value) return
  let {tableData: list} = matchRefs.value
  let {formData} = buyInfoRefs.value
  if (formData.addressId) {
    addressInfo.value = formData
  }
  getShopOrderPrice({
    contactUser: formData.name,
    contactMobile: formData.mobile,
    contactAddress: formData.address,
    list: list,
  }).then(res => {
    if (res.code !== 0) proxy.$message.error(res.msg)
    totalPrice.total = proxy.$filters.toYuan(res.data.total, '元')
    totalPrice.diff = proxy.$filters.toYuan(res.data.diff, '元', true)
  })
}
onMounted(() => {
  change()
  //刷新页面
})
const handleClick = () => {
  if (!matchRefs.value || !buyInfoRefs.value) return
  let {formData} = buyInfoRefs.value
  let {tableData: list} = matchRefs.value
  if (list.findIndex(item => item.count > 0) === -1) return proxy.$message.error('请至少选择一件商品')
  if (!formData.addressId) {
    proxy.$message.error('请选择收货地址')
    return
  }
  createShopOrder({
    contactUser: formData.name,
    contactMobile: formData.mobile,
    contactAddress: formData.address,
    list: list,
  }).then(res => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    reactiveAssign(orderInfo, res.data)
    confirmDiaRefs.value.showDia = true
  })
}
const closeConfirm = () => {
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shopOrder",
});
</script>
<style lang="scss" src="@views/shop/assets/common.scss" scoped>
</style>

<style lang="scss" scoped>
.order-head {
  background-color: $mainColor;
  padding-top: 104px;
}

.shop-header {
  background-color: $mainColor;
}
</style>
